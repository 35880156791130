import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// create new ticket
export const newTicket = createAsyncThunk(
  "ticket/newTicket",
  async (ticketInfo: any, { rejectWithValue }) => {
    try {
      const data = new FormData();
      //eslint-disable-next-line
      ticketInfo.files.map((file: any) => {
        data.append("files", file);
      });
      data.append("categoryId", ticketInfo.categoryId);
      data.append("description", ticketInfo.description);
      data.append("parkingName", ticketInfo.parkingName)
      data.append("currentLanguage", ticketInfo.currentLanguage)
      const result = await axios.post(`create-ticket`, data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// get all tickets of client
export const getTicketsClient = createAsyncThunk(
  "ticket/getTickets",
  async (_, { rejectWithValue }) => {
    try {

      const result = await axios.get(`get-all-tickets-client`);

      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get all tickets of client
export const cancelReclamation = createAsyncThunk(
  "ticket/cancelReclamation",
  async (id: string, { rejectWithValue }) => {
    try {

      const result = await axios.post(`cancel-ticket/${id}`);
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    Ticket: {},
    Tickets: [],
    loading: false,
    description: null,

  },
  reducers: {
    clearErrors: (state) => {
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get tickets client
      .addCase(getTicketsClient.pending, (state) => {
        state.Tickets = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getTicketsClient.fulfilled, (state, action) => {
        state.loading = false;
        state.Tickets = action.payload.Tickets;
        state.description = action.payload.Description;
      })
      .addCase(getTicketsClient.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.Tickets = [];
      })
      // create new ticket
      .addCase(newTicket.pending, (state) => {
        state.Ticket = {};
        state.loading = true;
        state.description = null;
      })
      .addCase(newTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.Ticket = action.payload.Ticket;
        state.Tickets = action.payload.Tickets;
        state.description = action.payload.Description;
      })
      .addCase(newTicket.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.Ticket = {};
      })
      //cancel reclamation
      .addCase(cancelReclamation.pending, (state) => {
        state.Tickets = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(cancelReclamation.fulfilled, (state, action) => {
        state.loading = false;
        state.Tickets = action.payload.Tickets;
        state.description = action.payload.Description;
      })
      .addCase(cancelReclamation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.Tickets = [];
      });
  },
});

export default ticketSlice.reducer;
