import { createTheme, ThemeProvider } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import React, { FC } from "react";
import Isabelle from "./../../../assets/images/isabelle.svg";
import { customFabDesign } from "../../../styles/ChatBot/CustomFab.style";
import { IconImageDesign } from "../../../styles/ChatBot/ChatBot.style";

declare module "@mui/material/styles" {
  interface Palette {
    custom: Palette["primary"];
  }

  interface PaletteOptions {
    custom?: PaletteOptions["primary"];
  }
}
let theme = createTheme({});

theme = createTheme(theme, {
  palette: {
    custom: theme.palette.augmentColor({
      color: {
        main: "#F39325",
      },
      name: "custom",
    }),
  },
});

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    custom: true;
  }
}

interface ICustomFab {
  shake?: boolean;
  openDiscussion?: boolean;
  handleClick?(...args: unknown[]): unknown;
}

const CustomFab: FC<ICustomFab> = ({ shake, openDiscussion, handleClick }) => {
  return (
    <ThemeProvider theme={theme}>
      <Fab
        size="large"
        color="custom"
        sx={{
          animation: shake && !openDiscussion ? "shake 0.3s" : "none",
          ...customFabDesign,
        }}
        onClick={handleClick}
      >
        <img
          src={Isabelle}
          alt="isabelle"
          unselectable="on"
          style={IconImageDesign}
          onContextMenu={(e) => e.preventDefault()}
        />
      </Fab>
    </ThemeProvider>
  );
};

export default CustomFab;
