import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  messageContainer,
  messageDesign,
} from "../../../styles/ChatBot/CustomMessages.style";

export type TMessage = {
  text: string;
  user: "me" | "other";
};

interface ICustomMessages {
  index?: number;
  message: TMessage;
}

export const CustomMessages: FC<ICustomMessages> = ({ index = 0, message }) => {
  return (
    <Box
      key={index}
      sx={{
        justifyContent: message.user === "me" ? "flex-end" : "flex-start",
        ...messageContainer,
      }}
    >
      <Typography
        sx={{
          backgroundColor: message.user === "me" ? "#1B374C" : "#f1f1f1",
          color: message.user === "me" ? "white" : "black",
          ...messageDesign,
        }}
      >
        {message.text}
      </Typography>
    </Box>
  );
};
