import React from "react";

// Define the type for the props
interface StoreButtonProps {
  imgHref: string;
  imgAlt: string;
  imgSrc: string;
  width?: string;
  height?: string;
  style?: Record<string, string>;
}

const StoreButton: React.FC<StoreButtonProps> = ({
  imgHref,
  imgAlt,
  imgSrc,
  width,
  height,
  style,
}) => {
  return (
    <a
      href={imgHref}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none", cursor: "pointer" }}
    >
      <img
        src={imgSrc}
        alt={imgAlt}
        width={width ? width : "100%"}
        height={height ? height : "100%"}
        style={style}
      />
    </a>
  );
};

export default StoreButton;
