import { keyframes, styled } from "@mui/material";

export const TypingMessageDesign = {
  bgcolor: "#D3D3D3",
  height: "20px",
  position: "relative",
  display: "flex",
  alignItems: "center",
  zIndex: "1055",
  borderRadius: "40%",
};

export const typing = keyframes`
    0%, 100% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-2px);
    }
      75% {
      transform: translateY(2px);
    }
  `;
