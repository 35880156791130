import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
// import axios from "axios";
import axios from "axios";
import { handleLogout } from "../../utils/getToken";

//update key account password
export const keyaccountupdatePassword = createAsyncThunk(
  "user/updatepassword",
  async (info: object, { rejectWithValue }) => {
    try {
      const result = await axios.post("password-update", info, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get current user info
export const currentUser = createAsyncThunk(
  "user/current",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get("get-current-user", {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// login user
export const login = createAsyncThunk(
  "user/login",
  async (loginInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(`login`, loginInfo, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// register user
export const register = createAsyncThunk(
  "user/register",
  async (registerInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post("register", registerInfo, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.href = `/profile`;
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// logout user
export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get("logout", {
        withCredentials: true,
      });
      if (result.data.Success) {
        handleLogout()
        window.location.href = "/";
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Resend verification email
export const resendOTP = createAsyncThunk(
  "user/resendOTP",
  async (userEmail: String, { rejectWithValue }) => {
    try {
      const result = await axios.post("sendverificationemail", userEmail, {
        withCredentials: true,
      });

      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Verify account
export const verifyOTP = createAsyncThunk(
  "user/verifyOTP",
  async (userID: String, { rejectWithValue }) => {
    try {
      const result = await axios.put(`/verify-user-account/${userID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.href = `/profile`;
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Send forgot password email
export const forgotPSWEmail = createAsyncThunk(
  "user/forgotPSWEmail",
  async (data: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `/forgot-password-email`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Change password
export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async ({ token, newPassword }: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `/change-password/${token}`,
        { newPassword },
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// resend verification email
export const resendVerifyToken = createAsyncThunk(
  "user/resendVerifyToken",
  async (currentLanguage:String, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/resend-verification-email`, {currentLanguage} ,{
        withCredentials: true,
      });

      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// verify account
export const verifyAccount = createAsyncThunk(
  "user/verifyAccount",
  async (token: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/verify-account?token=${token}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Verify key account OTP
export const VerifyOTPForKeyAccount = createAsyncThunk(
  "user/keyAccount-otp-verification",
  async (info: object, { rejectWithValue }) => {
    try {
      const result = await axios.post("key-account-verify-otp", info, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Resend OTP for key account
export const ResendOTPForKeyAccount = createAsyncThunk(
  "user/key-account-resend-otp",
  async (info: object, { rejectWithValue }) => {
    try {
      const result = await axios.post("key-account-resend-otp", info, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//OAuth GOOGLE
//CallbackGoogle
export const callbackGoogle = createAsyncThunk(
  "user/googleCallback",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/google-callback`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//OAuth FACEBOOK
//CallbackFacebook
export const CallbackFacebook = createAsyncThunk(
  "user/facebookCallback",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/facebook-callback`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const twoFactorAuthSendOtp = createAsyncThunk(
  "user/twoFactorAuthSendOtp",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/two-factor-auth-send-otp`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Check OTP for two factor authentication
export const twoFactorAuthCheckOtp = createAsyncThunk(
  "user/twofactorAuthCheckOtp",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/two-factor-auth-check-otp`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Edit profile

// update password Token Verification
export const tokenVerification = createAsyncThunk(
  "user/tokenVerification",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/token-verification`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// GET notifications
export const getNotifications = createAsyncThunk(
  "user/getNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-notifications`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// DELETE notifications
export const deleteNotification = createAsyncThunk(
  "user/deleteNotification",
  async (notificationID: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `/delete-notification`,
        {notificationID},{
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// UPDATE notifications
export const updateNotification = createAsyncThunk(
  "user/updateNotification",
  async (notificationID: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(`/notification-read`, {notificationID}, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    loading: false,
    errors: null,
    isAuth: false,
    success: false,
    userOAuth: {},
    notifications: {},
  },
  reducers: {
    logout: (state) => {
      state.isAuth = false;
    },
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //key account update password
      .addCase(keyaccountupdatePassword.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
      })
      .addCase(keyaccountupdatePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.success = true;
        state.user = action.payload.User;
        state.isAuth = true;
      })
      .addCase(keyaccountupdatePassword.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      //Login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.User;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(login.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // Register
      .addCase(register.pending, (state) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.User;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(register.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.user = {};
        state.success = false;
      })
      // Current user
      .addCase(currentUser.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(currentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.Data;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(currentUser.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.user = {};
        state.success = false;
      })
      // Logout user
      .addCase(logoutUser.pending, (state) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(logoutUser.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // Resend OTP
      .addCase(resendOTP.pending, (state) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(resendOTP.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // OTP verified
      .addCase(verifyOTP.pending, (state) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
        state.user = action.payload.User;
      })
      .addCase(verifyOTP.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.user = {};
      })
      // Send forgot password email
      .addCase(forgotPSWEmail.pending, (state) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(forgotPSWEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
        state.user = {};
      })
      .addCase(forgotPSWEmail.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
        state.user = {};
      })
      // Send forgot password email
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.user = action.payload.User;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // resendVerifyToken
      .addCase(resendVerifyToken.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(resendVerifyToken.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(resendVerifyToken.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // verify account
      .addCase(verifyAccount.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = false;
        state.user = action.payload.User;
        state.errors = null;
        state.success = true;
      })
      .addCase(verifyAccount.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // Verify OTP For Key Account
      .addCase(VerifyOTPForKeyAccount.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
      })
      .addCase(VerifyOTPForKeyAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = null;
        state.success = true;
      })
      .addCase(VerifyOTPForKeyAccount.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      // Resend OTP For Key Account
      .addCase(ResendOTPForKeyAccount.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
      })
      .addCase(ResendOTPForKeyAccount.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = null;
        state.success = true;
      })
      .addCase(ResendOTPForKeyAccount.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isAuth = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      .addCase(callbackGoogle.pending, (state: any, action: any) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(callbackGoogle.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.user = action.payload.User;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(callbackGoogle.rejected, (state: any, action: any) => {
        state.user = {};
        state.loading = false;
        state.errors = action.payload.Description;
        state.isAuth = false;
        state.success = false;
        state.userOAuth = action.payload.User;
      })
      .addCase(CallbackFacebook.pending, (state: any, action: any) => {
        state.user = {};
        state.loading = true;
        state.errors = null;
        state.isAuth = false;
        state.success = false;
      })
      .addCase(CallbackFacebook.fulfilled, (state: any, action: any) => {
        state.loading = false;
        state.user = action.payload.User;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      .addCase(CallbackFacebook.rejected, (state: any, action: any) => {
        state.user = {};
        state.loading = false;
        state.errors = action.payload.Description;
        state.isAuth = false;
        state.success = false;
        state.userOAuth = action.payload.User;
      })
      //twoFactorAuthCheckOtp
      .addCase(twoFactorAuthCheckOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.User;
        state.isAuth = true;
        state.errors = null;
        state.success = true;
      })
      //token Verification
      .addCase(tokenVerification.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
      })
      .addCase(tokenVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.success = true;
      })
      .addCase(tokenVerification.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = false;
      })
      //GET notifications
      .addCase(getNotifications.pending, (state) => {
        state.errors = null;
        state.success = false;
      })
      .addCase(getNotifications.fulfilled, (state: any, action: any) => {
        state.errors = null;
        state.notifications = action.payload.Data;
        state.success = true;
      })
      .addCase(getNotifications.rejected, (state: any, action: any) => {
        state.errors = action.payload.Description;
        state.success = false;
      })
      //DESTORY notifications
      .addCase(deleteNotification.pending, (state) => {
        state.errors = null;
        state.success = false;
      })
      .addCase(deleteNotification.fulfilled, (state: any, action: any) => {
        state.errors = null;
        state.notifications = action.payload.Data;
        state.success = true;
      })
      .addCase(deleteNotification.rejected, (state: any, action: any) => {
        state.errors = action.payload.Description;
        state.success = false;
      })
      //UPDATE notifications
      .addCase(updateNotification.pending, (state) => {
        state.errors = null;
        state.success = false;
      })
      .addCase(updateNotification.fulfilled, (state: any, action: any) => {
        state.errors = null;
        state.notifications = action.payload.Data;
        state.success = true;
      })
      .addCase(updateNotification.rejected, (state: any, action: any) => {
        state.errors = action.payload.Description;
        state.success = false;
      });
  },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions;
export const { logout, clearErrors } = authSlice.actions;
// export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
