export const customFabDesign = {
  border: "3px solid white",
  "@keyframes shake": {
    "0%": { transform: "translateX(0)" },
    "25%": { transform: "translateX(-5px)" },
    "50%": { transform: "translateX(5px)" },
    "75%": { transform: "translateX(-5px)" },
    "100%": { transform: "translateX(0)" },
  },
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  overflow: "hidden",
};
