import React from "react";
import { Box, Typography } from "@mui/material";
import StoreButton from "../Buttons/StoreButton";
import {
  subHeaderAnnounceContainer,
  storeButtonsContainer,
  typographyAnnounce,
} from "./subNavBar.style";
import { useTranslation } from "react-i18next";

const subNavBar = (language: any, scriptToDisplay: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return (
    <Box sx={subHeaderAnnounceContainer}>
      <Typography sx={typographyAnnounce}>
        {t(
          "RÉSERVEZ, GÉREZ VOS ABONNEMENTS, ET PAYEZ EN UN CLIC AVEC NOTRE NOUVELLE APPLICATION MOBILE!"
        )}
      </Typography>
      <Box sx={storeButtonsContainer}>
        <StoreButton
          imgHref="https://play.google.com/store/apps/details?id=be.effia.effia_park"
          imgAlt="Play Store"
          imgSrc={`/ploaystore/playstore-${language.language}.svg`}
          width="115px"
          height="38px"
        />
        <StoreButton
          imgHref="https://apps.apple.com/tn/app/effia-belgium/id6739697337"
          imgAlt="App Store"
          imgSrc={`/appstore/appstore-${language.language}.svg`}
          width="115px"
          height="38px"
        />
      </Box>
    </Box>
  );
};

export default subNavBar;
