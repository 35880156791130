// ** React imports
import React, { FC, useState, ChangeEvent, MouseEvent, useEffect } from "react";

// ** MUI imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormHelperText from "@mui/material/FormHelperText";

// ** Redux imports
import { register } from "../../redux/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks";

// ** Third party imports
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { IconButton, InputAdornment, LinearProgress } from "@mui/material";

import checkPasswordStrength from "../../utils/CheckPasswordStrength";
import { EyeOffOutline, EyeOutline, InformationOutline } from "mdi-material-ui";
import { useSelector } from "react-redux";
import validateEmail from "../../functions/validateEmail";
import { checkVatNumber } from "../../redux/vat/vatSlice";

const ToggleButtonStyled = styled(ToggleButton)(() => ({
  border: "none",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#F39325",
    backgroundColor: "transparent",
  },
}));

const Register: FC<any> = ({ openLogin }) => {
  // Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // States
  const [registerInfo, setRegisterInfo] = useState<any>({
    gender: "female",
    userType: "particular",
    currentLanguage: currentLanguage ? currentLanguage.toUpperCase() : "FR",
  });

  const [userType, setUserType] = useState<String>("particular");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState<any>(null);
  const [messageStrength, setMessageStrength] = useState<any>(null);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [passwordState, setPasswordState] = useState<any>(null);
  const [checkboxState, setCheckboxState] = useState(false);
  const [passwordNotConfirm, setPasswordNotConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [passwordColor, setPasswordColor] = useState("#e3e3e3");
  const [isEmailValid, setIsEmailValid] = useState("empty");
  const [isFullnameValid, setIsFullnameValid] = useState("empty");
  const [isCompanyNameValid, setIsCompanyNameValid] = useState("empty");
  const [isVATValid, setIsVATValid] = useState<boolean>(true);

  // Hooks
  const dispatch = useAppDispatch();
  const userDataOAuth = useSelector((state: any) => state.auth.userOAuth);

  // ** check name validation
  const isNameValid = (value: string) => {
    const cleanedValue = value.trim().replace(/\s+/g, " ");
    return (
      /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(cleanedValue) &&
      cleanedValue.length >= 2
    );
  };

  // handle change
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "fullName") {
      const isValid = isNameValid(value);
      if (isValid) {
        setIsFullnameValid("valid");
      } else {
        setIsFullnameValid("invalid");
      }
    } else if (name === "companyName") {
      const isValid = isNameValid(value);
      if (isValid) {
        setIsCompanyNameValid("valid");
      } else {
        setIsCompanyNameValid("invalid");
      }
    }
    setRegisterInfo({
      ...registerInfo,
      [name]: value,
    });
  };

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterInfo({
      ...registerInfo,
      [event.target.name]: event.target.checked,
    });
    setCheckboxState(event.target.checked);
  };

  const handleChangeUserType = (
    event: MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setUserType(newAlignment);
    setRegisterInfo({ ...registerInfo, userType: newAlignment });
  };

  // Register dispatch
  const dispatchRegister = async () => {
    setLoadingBtn(true);
    let startDispatch = false;

    if (userType === "professional" && registerInfo?.tvaNumber) {
      await dispatch(
        checkVatNumber({
          tvaNumber: registerInfo?.tvaNumber,
        })
      ).then((result: any) => {
        if (result?.payload?.Success && result?.payload?.isValid) {
          setIsVATValid(true);
          startDispatch = true;
        } else {
          setLoadingBtn(false);
          setIsVATValid(false);
          startDispatch = false;
        }
      });
    } else {
      startDispatch = true;
    }

    if (startDispatch) {
      await dispatch(register(registerInfo)).then((result: any) => {
        setLoadingBtn(false);
      });
    }
  };

  // const validateEmail = (email: string): boolean => {
  //   // Use a regular expression for basic email validation
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  // handle change for email input
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Validate email
    const isValid = validateEmail(value);

    // Update state and perform other actions if needed
    setRegisterInfo({
      ...registerInfo,
      [name]: value,
      // You can use isValid in your logic, e.g., to show error messages
    });
    setIsEmailValid(isValid ? "valid" : "invalid");
  };

  // verify same password
  //eslint-disable-next-line
  useEffect(() => {
    if (registerInfo.password) {
      setPasswordState(registerInfo.password);
    } else if (!registerInfo.password) {
      setPasswordState(null);
      setProgressValue(0);
      setMessageStrength(null);
      setPasswordColor("#e3e3e3");
    }

    if (registerInfo.confirmPassword) {
      if (registerInfo.password === registerInfo.confirmPassword) {
        setPasswordNotConfirm(false);
      } else {
        setPasswordNotConfirm(true);
      }
    }

    if (!registerInfo.password) {
      setPasswordNotConfirm(false);
      setPasswordStrength(null);
    }
  });

  // password strength + messages + progress + color + disabled btn
  useEffect(() => {
    let confirm = false;
    if (passwordState !== null) {
      const strength = checkPasswordStrength(passwordState);

      // password strength, message and color
      if (strength) {
        const { result, msg, value, color } = strength;

        confirm = strength?.confirm;

        if (currentLanguage === "fr") {
          setPasswordStrength(result.fr);
          setMessageStrength(msg.fr);
        } else if (currentLanguage === "en") {
          setPasswordStrength(result.en);
          setMessageStrength(msg.en);
        } else {
          setPasswordStrength(result.nl);
          setMessageStrength(msg.nl);
        }

        setProgressValue(value);
        setPasswordColor(color);
      }
    }

    // Determine if the button should be disabled
    const commonChecks =
      confirm &&
      !passwordNotConfirm &&
      registerInfo?.fullName &&
      registerInfo?.userEmail &&
      registerInfo?.gender &&
      registerInfo?.password &&
      registerInfo?.confirmPassword &&
      isEmailValid === "valid" &&
      isFullnameValid === "valid";

    setIsButtonDisabled(
      userType === "particular"
        ? commonChecks
        : commonChecks &&
            registerInfo?.companyName &&
            isCompanyNameValid === "valid" &&
            registerInfo?.tvaNumber
    );
  }, [
    passwordState,
    checkboxState,
    progressValue,
    passwordNotConfirm,
    registerInfo,
    passwordColor,
    userType,
    currentLanguage,
    isEmailValid,
    isFullnameValid,
    isCompanyNameValid,
  ]);

  // if OAuth => get email and fullname
  useEffect(() => {
    if (userDataOAuth) {
      // Validate email
      if (userDataOAuth.userEmail) {
        const isValid = validateEmail(userDataOAuth.userEmail);
        setIsEmailValid(isValid ? "valid" : "invalid");
      }

      setRegisterInfo({
        ...registerInfo,
        userEmail: userDataOAuth.userEmail,
        fullName: userDataOAuth.fullName,
      });
    }
  }, [userDataOAuth]);

  useEffect(() => {
    if (registerInfo?.userEmail === "") {
      setIsEmailValid("empty");
    }
    if (registerInfo?.fullName === "") {
      setIsFullnameValid("empty");
    }
    if (registerInfo?.companyName === "") {
      setIsCompanyNameValid("empty");
    }
    // if (registerInfo?.tvaNumber === "") {
    //   setIsvalidTVANumber(true);
    // }
  }, [
    registerInfo?.userEmail,
    registerInfo?.fullName,
    registerInfo?.companyName,
    registerInfo?.tvaNumber,
  ]);

  return (
    <>
      <DialogTitle sx={{ textAlign: "center", pt: 3, pb: 0 }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "16px",
            mb: 1,
            textTransform: "uppercase",
          }}
          color="secondary">
          {t("inscription")}
        </Typography>
        <ToggleButtonGroup
          value={userType}
          exclusive
          onChange={handleChangeUserType}
          aria-label="Platform"
          color="secondary"
          sx={{ color: "#F39325", bgcolor: "transparent" }}>
          <ToggleButtonStyled disableRipple value="particular">
            {t("Particulier")}
          </ToggleButtonStyled>
          <ToggleButtonStyled disableRipple value="professional">
            {t("Professionnel")}
          </ToggleButtonStyled>
        </ToggleButtonGroup>
      </DialogTitle>
      <DialogContent sx={{ borderTop: "1px solid #F39325" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: ["0px 10px 30px 10px", "8px 40px 0px 40px"],
            gap: 1,
          }}>
          <FormControl sx={{ m: "auto" }}>
            <RadioGroup
              row
              name="gender"
              onChange={handleChange}
              value={registerInfo?.gender}>
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "16px",
                    }}>
                    {t("Madame")}
                  </span>
                }
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "18px",
                      lineHeight: "16px",
                    }}>
                    {t("Monsieur")}
                  </span>
                }
              />
            </RadioGroup>
          </FormControl>
          {/*  */}
          {userType === "professional" && (
            <>
              <FormControl
                sx={{
                  m: "auto",
                  border: "thin solid #d9d9d9",
                  borderRadius: "0.4rem",
                }}
                fullWidth>
                <TextField
                  type="text"
                  id="companyname"
                  name="companyName"
                  onChange={handleChange}
                  label={
                    <Box component="span" sx={{ px: "10px" }}>
                      {t("Entreprise")}
                      <sup style={{ color: "red" }}> *</sup>
                    </Box>
                  }
                  placeholder={t("Raison sociale")}
                  multiline
                  fullWidth
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: { color: "#8a8a8a", fontSize: "90%" },
                  }}
                  sx={{ px: "8px" }}
                  value={registerInfo?.companyName}
                />
              </FormControl>
              {isCompanyNameValid === "invalid" && (
                <FormHelperText style={{ color: "red" }}>
                  {t(
                    "Raison sociale doit comporter au moins 2 caractères et ne contenir aucun chiffre ni caractère spécial."
                  )}
                </FormHelperText>
              )}
            </>
          )}
          {/* INPUTS */}
          <FormControl
            sx={{
              m: "auto",
              border: "thin solid #d9d9d9",
              borderRadius: "0.4rem",
            }}
            fullWidth>
            <TextField
              type="text"
              id="fullname"
              name="fullName"
              onChange={handleChange}
              label={
                <Box component="span" sx={{ px: "10px" }}>
                  {t("Nom et Prénom")} <sup style={{ color: "red" }}> *</sup>
                </Box>
              }
              placeholder={t("Nom et Prénom")}
              multiline
              fullWidth
              variant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{
                style: { color: "#8a8a8a", fontSize: "90%" },
              }}
              sx={{ px: "8px" }}
              value={registerInfo?.fullName}
            />
          </FormControl>
          {isFullnameValid === "invalid" && (
            <FormHelperText style={{ color: "red" }}>
              {t(
                "Nom et prénom doit comporter au moins 2 caractères et ne contenir aucun chiffre ni caractère spécial"
              )}
            </FormHelperText>
          )}
          {/*  */}
          <FormControl
            sx={{
              m: "auto",
              border: "thin solid #d9d9d9",
              borderRadius: "0.4rem",
            }}
            fullWidth>
            <TextField
              id="email"
              name="userEmail"
              type="email"
              onChange={handleChangeEmail}
              label={
                <Box component="span" sx={{ px: "10px" }}>
                  {t("Email")}
                  <sup style={{ color: "red" }}> *</sup>
                </Box>
              }
              placeholder="email@mail.com"
              fullWidth
              variant="standard"
              InputProps={{ disableUnderline: true }}
              InputLabelProps={{
                style: { color: "#8a8a8a", fontSize: "90%" },
              }}
              sx={{ px: "8px" }}
              value={registerInfo?.userEmail}
              autoComplete="new-email"
            />
          </FormControl>
          {isEmailValid === "invalid" && (
            <FormHelperText style={{ color: "red" }}>
              {t("Votre adresse e-mail est invalide.")}
            </FormHelperText>
          )}
          <FormControl
            sx={{
              m: "auto",
              border: "thin solid #d9d9d9",
              borderRadius: "0.4rem",
              position: "relative",
            }}
            fullWidth>
            <TextField
              id="password"
              name="password"
              onChange={handleChange}
              label={
                <Box component="span" sx={{ px: "10px" }}>
                  {t("Mot de passe")}
                  <sup style={{ color: "red" }}> *</sup>
                </Box>
              }
              fullWidth
              variant="standard"
              type={showPassword ? "text" : "password"}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        marginTop: "-18px",
                        marginRight: "10px",
                      }}>
                      {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: { color: "#8a8a8a", fontSize: "90%" },
              }}
              sx={{ px: "8px" }}
              autoComplete="new-password"
            />
          </FormControl>
          {registerInfo?.password && (
            <>
              {progressValue !== 100 && (
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: progressValue !== 100 ? "red" : "black",
                  }}>
                  {messageStrength}
                </Typography>
              )}
              <LinearProgress
                variant="determinate"
                value={progressValue}
                sx={{
                  bgcolor: "#e3e3e3",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: passwordColor,
                  },
                }}
              />
              <Typography
                sx={{
                  alignSelf: "flex-end",
                  color: passwordColor,
                  fontSize: "13px",
                }}>
                {passwordStrength}
              </Typography>
            </>
          )}
          <FormControl
            sx={{
              m: "auto",
              border: "thin solid #d9d9d9",
              borderRadius: "0.4rem",
              position: "relative",
            }}
            fullWidth>
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              onChange={handleChange}
              label={
                <Box component="span" sx={{ px: "10px" }}>
                  {t("Confirmer le mot de passe")}
                  <sup style={{ color: "red" }}> *</sup>
                </Box>
              }
              fullWidth
              variant="standard"
              type={showPasswordConfirm ? "text" : "password"}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowPasswordConfirm(!showPasswordConfirm)
                      }
                      style={{
                        marginTop: "-18px",
                        marginRight: "10px",
                      }}>
                      {showPasswordConfirm ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ px: "8px" }}
              InputLabelProps={{
                style: { color: "#8a8a8a", fontSize: "90%" },
              }}
            />
          </FormControl>
          {registerInfo?.confirmPassword && passwordNotConfirm && (
            <Typography
              sx={{
                alignSelf: "flex-end",
                color: "red",
                fontSize: "13px",
              }}>
              {passwordNotConfirm &&
                (currentLanguage === "fr"
                  ? "Le mot de passe n'est pas identique"
                  : currentLanguage === "en"
                  ? "The password is not identical"
                  : "Het wachtwoord is niet identiek")}
            </Typography>
          )}
          {/* TVA number input */}
          {userType === "professional" && (
            <>
              <FormControl
                sx={{
                  m: "auto",
                  border: "thin solid #d9d9d9",
                  borderRadius: "0.4rem",
                }}
                fullWidth>
                <TextField
                  type="text"
                  id="tvaNumber"
                  name="tvaNumber"
                  onChange={handleChange}
                  label={
                    <Box component="span" sx={{ px: "10px" }}>
                      {t("Numéro TVA")}
                      <sup style={{ color: "red" }}> *</sup>
                    </Box>
                  }
                  placeholder="EX: BE0123456789"
                  multiline
                  fullWidth
                  variant="standard"
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    style: { color: "#8a8a8a", fontSize: "90%" },
                  }}
                  sx={{ px: "8px" }}
                  value={registerInfo?.tvaNumber}
                />
              </FormControl>
              {registerInfo?.tvaNumber && !isVATValid && (
                <FormHelperText
                  sx={{
                    color: "red",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}>
                  <InformationOutline sx={{ width: "20px" }} />
                  <Typography sx={{ fontSize: "13px" }}>
                    {t("Le numéro de TVA fourni n'est pas valide")}
                  </Typography>
                </FormHelperText>
              )}
            </>
          )}
          <FormControlLabel
            name="condition"
            value="end"
            control={
              <Checkbox
                onChange={handleChangeCheckbox}
                value={registerInfo?.checkboxState}
              />
            }
            label={
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "14px",
                }}>
                {t("J’accepte de recevoir des offres commerciales d’EFFIA")}
              </span>
            }
            labelPlacement="end"
          />
          <CustomizedButton
            onClick={() => {
              dispatchRegister();
            }}
            loading={loadingBtn}
            disabled={!isButtonDisabled}
            sx={{
              ".css-62e83j-MuiCircularProgress-root": { color: "white" },
              "&[disabled]": {
                backgroundColor: "#e3e3e3",
                color: "#b9b9b9",
                cursor: "not-allowed",
              },
              height: "45px",
            }}>
            {t("Créer un nouveau compte")}
          </CustomizedButton>

          <Typography
            sx={{
              m: "5px auto 0px auto",
              fontWeight: "300",
              fontSize: "16px",
            }}>
            {t("Vous avez déja un compte ?")}
            <span
              onClick={openLogin}
              style={{
                color: "#F39325",
                cursor: "pointer",
                fontWeight: "500",
              }}>
              {" "}
              {t("connectez-vous !")}
            </span>
          </Typography>
        </Box>
      </DialogContent>
    </>
  );
};

const CustomizedButton = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#1b374c",
  fontFamily: "Fira Sans, sans-serif",
  padding: "1em 2em",
  "&:hover": {
    backgroundColor: "#162c3d",
    color: "white",
  },
}));

export default Register;
