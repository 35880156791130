export const subHeaderAnnounceContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: { xs: "column", md: "row" },
  gap: { xs: 1, md: 4 },
  backgroundColor: "#F39325",
  height: "100%",
  border: "5px solid",
  borderColor: "#F39325",
};

export const typographyAnnounce = {
  color: "black",
  fontSize: { xs: "80%", sm: "80%", md: "100%" },
  fontFamily: "Fira Sans, sans-serif",
  alignItems: "center",
  textAlign: { xs: "center", sm: "none" },
};

export const storeButtonsContainer = { gap: { xs: 1, md: 4 }, display: "flex" };
