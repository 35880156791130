import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Edit avatar
export const checkVatNumber = createAsyncThunk(
  "vat/vatVerification",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post("vat-verification", data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const vatSlice = createSlice({
  name: "vat",
  initialState: {
    loading: false,
    success: false,
    isValid: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkVatNumber.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.isValid = true;
      })
      .addCase(checkVatNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.isValid = action.payload.isValid;
        state.success = true;
      })
      .addCase(checkVatNumber.rejected, (state: any, action: any) => {
        state.loading = false;
        state.isValid = action.payload.isValid;
        state.success = false;
      });
  },
});

export default vatSlice.reducer;
