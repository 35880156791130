export const messageContainer = { display: "flex", marginBottom: 1 };

export const messageDesign = {
  padding: "8px 16px",
  borderRadius: "12px",
  maxWidth: "70%",
  width: "fit-content",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
};

export const typingInnerMessageContainer = {
  display: "flex",
  width: "93%",
  justifyContent: "flex-start",
  paddingTop: "3px",
  marginBottom: 1,
};

// send message
export const sendMessageContainer = {
  width: "100%",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 1,
  paddingLeft: "10px",
  paddingTop: "3px",
  paddingBottom: "3px",
  bgcolor: "white",
  borderTop: "1px solid #E8E8E8",
};

export const sendMessageInputDesign = {
  borderRadius: "20px",
  paddingTop: "10px",
  paddingBottom: "10px",
  backgroundColor: "#F1F0F0",
  "& .MuiOutlinedInput-root": {
    padding: "0",
    border: "none",
  },
  "& .MuiInputBase-input": {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#333",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&:hover .MuiOutlinedInput-root": {
    backgroundColor: "#E8E8E8",
  },
  "& .MuiInputBase-root": {
    borderRadius: "20px",
  },
};
