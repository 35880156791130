export const chatBotContainer = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "flex-end",
  gap: 1,
};

export const floatingButtonDesign = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "start",
  gap: 1,
};

export const floatingMessageDesign = {
  position: "relative",
  right: "0%",
  backgroundColor: "white",
  padding: "5px",
  borderRadius: "12px",
  boxShadow: 3,
  animation: "slide-in 0.3s ease-out",
  zIndex: "60",
  "::after": {
    content: '""',
    position: "absolute",
    right: "-8px",
    top: "60%",
    transform: "translateY(-5%)",
    width: 0,
    height: 0,
    borderTop: "5px solid transparent",
    borderBottom: "4.7px solid transparent",
    borderLeft: "10px solid white",
    "@keyframes slide-in": {
      "0%": {
        opacity: 0,
        transform: "translateX(20px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateX(0)",
      },
    },
  },
};

export const notifMessageDesign = {
  bgcolor: "red",
  zIndex: "1055",
  height: "20px",
  width: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  position: "absolute",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
  animation: "slide-in 0.1s ease-out",
  "@keyframes slide-in": {
    "0%": {
      opacity: 0,
    },
    "50%": {
      opacity: 0.7,
    },
    "100%": {
      opacity: 1,
    },
  },
};

export const notifTextDesign = {
  color: "white",
  fontWeight: "500",
  fontSize: "12px",
};

// discussion design

export const discussionInterfaceContainer = {
  width:"24rem",
  height:"30rem",
  borderRadius: "3%",
  animation: "slide-in 0.3s ease-out",
  zIndex: "60",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: 3,
  overflow: "hidden",
};

export const discussionInterfaceContainerMobile = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  zIndex: 9999,
  flexDirection: "column",
  justifyContent: "space-between",
};

export const discussionHeaderContainer = {
  bgcolor: "#1B374C",
  width: "100%",
  height: "100%",
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
}
export const discussionHeaderContainerMobile = {
  width: "100%",
  height: "100%",
  bgcolor: "#1B374C",
  display: "flex",
  alignItems: "center",
  gap: 1,
  justifyContent: "space-around",
  flex: 0.5,
};

export const IconContainer = {
  bgcolor: "#F39325",
  width: "40px",
  height: "40px",
  overflow: "hidden",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
};

export const IconImageDesign: Record<string | number, string & {}> = {
  objectFit: "contain",
  maxWidth: "170%",
  maxHeight: "180%",
  pointerEvents: "none",
};

export const ChatBotTextCOntainer = {
  color: "white",
  display: "flex",
  flexDirection: "column",
};

export const ChatPrimaryText = { fontWeight: 500, fontSize: 18 };
export const ChatSecondaryText = { fontWeight: 10, fontSize: 11 };
export const closeButtonDesign = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
};
export const MessagesContainerDesign = {
  flex: 6,
  overflowY: "auto",
  bgcolor: "white",
  width:"100%",
  display:"flex",
  flexDirection:"column",
  alignItems: "center",
  paddingTop:"5px",
  marginBottom:"-0.2px"
};
