import Box from "@mui/material/Box";
import React, { FC } from "react";
import { styled } from "@mui/material";
import {
  typing,
  TypingMessageDesign,
} from "../../../styles/ChatBot/TypingMessage.style";

const TypingDot = styled("span")(({ theme }) => ({
  width: 6,
  height: 6,
  backgroundColor: theme.palette.text.secondary,
  borderRadius: "50%",
  margin: "0 2px",
  animation: `${typing} 1s infinite ease-in-out`,
}));

interface ITypingMessage {
  sx?: Record<string | number, string & {}>;
}
const TypingMessage: FC<ITypingMessage> = ({ sx }) => {
  return (
    <Box
      sx={{
        ...TypingMessageDesign,
        ...sx,
      }}
    >
      <TypingDot />
      <TypingDot sx={{ animationDelay: "0.2s" }} />
      <TypingDot sx={{ animationDelay: "0.4s" }} />
    </Box>
  );
};

export default TypingMessage;
