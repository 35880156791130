import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// create new subscription
export const newSubs = createAsyncThunk(
  "subscription/newSubs",
  async (
    {
      documents,
      createSubInfo,
      userUpdateInfo,
      language,
      confirmSaveData,
    }: any,
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      //eslint-disable-next-line
      documents.map((document: any) => {
        formData.append("files", document);
      });
      formData.append("parkingId", createSubInfo?.parkingId);
      formData.append("startTime", createSubInfo?.startTime);
      formData.append("endTime", createSubInfo?.endTime);
      formData.append(
        "vehicleDriver",
        JSON.stringify(createSubInfo?.vehicleDriver)
      );
      formData.append("vehicleNb", JSON.stringify(createSubInfo?.vehicleNb));
      formData.append("subscriptionType", createSubInfo?.subscriptionType);
      formData.append("subscriptionId", createSubInfo?.subscriptionId);
      formData.append("subscriptionTypeId", createSubInfo?.subscriptionTypeId);
      formData.append("amount", createSubInfo?.tarif);
      formData.append("duration", JSON.stringify(createSubInfo?.duration));
      formData.append("cardPickUp", createSubInfo?.cardPickUp);
      formData.append("dateRange", JSON.stringify(createSubInfo?.dateRange));

      // ** user information

      formData.append("country", userUpdateInfo?.country);
      formData.append("adress", userUpdateInfo?.adress);
      formData.append("userPhone", userUpdateInfo?.userPhone);
      formData.append("zipCode", userUpdateInfo?.zipCode);
      formData.append("state", userUpdateInfo?.state);
      if (userUpdateInfo?.tvaNumber) {
        formData.append("tvaNumber", userUpdateInfo?.tvaNumber);
      }
      formData.append("userName", userUpdateInfo?.userName);
      formData.append("userEmail", userUpdateInfo?.userEmail);
      formData.append("codeClient", userUpdateInfo?.codeClient);
      formData.append("currentLanguage", language);

      // ** confirmation data
      formData.append("saveAddress", confirmSaveData?.saveAddress);
      formData.append("savePhoneNumber", confirmSaveData?.savePhoneNumber);
      formData.append("saveTvaNumber", confirmSaveData?.saveTvaNumber);

      const result = await axios.post(`/new-subscription`, formData, {
        withCredentials: true,
      });
      // if (!result.data.Success) {
      //   toast.error(result.data.Description, {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      // }
      return result.data;
    } catch (error: any) {
      // toast.error(error.response.data.Description, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's subscription
export const getAllUserSubs = createAsyncThunk(
  "subscription/getAllUserSubs",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-subscriptions`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get subscription by id
export const getSubById = createAsyncThunk(
  "subscription/getSubById",
  async (subscriptoinID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-subscription/${subscriptoinID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update subscription
export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async (updateInfo: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `edit-subscription/${updateInfo.subscriptoinID}`,
        updateInfo,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// cancel subscription
export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (subscriptoinID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(`cancel-subscription/${subscriptoinID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// cancel subscription
export const reactivateSubscription = createAsyncThunk(
  "subscription/reactivateSubscription",
  async (subscriptoinID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `reactivate-subscription/${subscriptoinID}`,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// cancel subscription
export const deleteSubscription = createAsyncThunk(
  "subscription/deleteSubscription",
  async (subscriptoinID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(`delete-subscription/${subscriptoinID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// cancel subscription
export const terminateSubscription = createAsyncThunk(
  "subscription/terminateSubscription",
  async (
    {
      subscriptionID,
      data,
    }: {
      subscriptionID: String | undefined;
      data: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `terminate-subscription/${subscriptionID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// Update user card
//const { tokenId, subscriptionID } = req.body;
export const updateUserCard = createAsyncThunk(
  "subscription/updateUserCard",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post("/update-card-recurring", data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//proccess of refused doc
export const uploadNewDocSub = createAsyncThunk(
  "subscription/uploadNewDocSub",
  async ({ documents, id, language }: any, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      //eslint-disable-next-line
      documents.map((document: any) => {
        formData.append("files", document);
      });

      formData.append("currentLanguage", language);

      const result = await axios.post(
        `/upload-new-document-subscription/${id}`,
        formData,
        {
          withCredentials: true,
        }
      );
      if (!result.data.Success) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
interface SubscriptionState {
  subscription: any | {};
  subscriptions: any[];
  loading: boolean;
  errors: null | string;
}

// Initial state
const initialState: SubscriptionState = {
  subscription: {},
  subscriptions: [],
  loading: false,
  errors: null,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // create new subscription
      .addCase(newSubs.pending, (state) => {
        state.subscription = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(newSubs.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscription = action.payload.Data;
      })
      .addCase(newSubs.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscription = {};
      })
      // get all user's subscription
      .addCase(getAllUserSubs.pending, (state) => {
        state.subscriptions = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAllUserSubs.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Subscriptions;
      })
      .addCase(getAllUserSubs.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // get subscription by id
      .addCase(getSubById.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(getSubById.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscription = action.payload.Subscription;
      })
      .addCase(getSubById.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscription = {};
      })
      // edit subscription
      .addCase(updateSubscription.pending, (state) => {
        state.subscription = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscription = action.payload.Subscription;
      })
      .addCase(updateSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscription = {};
      })
      // cancel subscription
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Data;
      })
      .addCase(cancelSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      }) // reactivate subscription
      .addCase(reactivateSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(reactivateSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Data;
      })
      .addCase(reactivateSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // terminate subscription
      .addCase(terminateSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(terminateSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Data;
      })
      .addCase(terminateSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // delete subscription

      .addCase(deleteSubscription.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Data;
      })
      .addCase(deleteSubscription.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      //** updateUserCard
      .addCase(updateUserCard.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(updateUserCard.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        const updatedSubscription = action.payload.Data;
        state.subscriptions = state.subscriptions.map((subscription) =>
          subscription.id === updatedSubscription.id
            ? updatedSubscription
            : subscription
        );
      })
      .addCase(updateUserCard.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      //** new files
      .addCase(uploadNewDocSub.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(uploadNewDocSub.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.subscriptions = action.payload.Data;
      })
      .addCase(uploadNewDocSub.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      });
  },
});

export default subscriptionSlice.reducer;
